import React from 'react';
import MainBanner from '../PageBanners/MainBannerCustom';
import About from '../Common/About';
import Ctr from '../Common/Ctr';
import HowWeWork from '../Common/HowWeWork';
import Services from '../Common/Services';
import WhyWeDifferent from '../Common/WhyWeDifferent';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
 
class Home extends React.Component {
    render(){
        return (
            <>
                {/* Main Banner */}
                <MainBanner />
                
                {/* Welcome Area
                <Welcome />
                */}
                
                {/* About Area */}
                <About />

                {/* Why We Are Different */}
                <WhyWeDifferent />

                {/* Strategy Area
                <Strategy />
                */}
                
                {/* Who We Are Area 
                <WhoWeAre />
                */}

                {/* CTR Area */}
                <Ctr />

                {/* Skill Area
                <Skill />
                */}

                {/* Fact Area 
                <Funfact />
                */}

                {/* Team Area
                <Team />
                */}

                {/* Services Area */}
                <Services />
                
                {/* How We Work */}
                <HowWeWork />

                {/* Work Area 
                <Work />
                */}

                {/* Price Area
                <Price />
                */}

                {/* FAQ Area
                <Faq />
                */}

                {/* Testimonials Area
                <Testimonial />
                */}

                {/* Blog Area
                <Blog />
                */}

                {/* Partner Area
                <Partner />
                */}

                {/* Subscribe Area
                <Subscribe />
                */}

                {/* Contact Area */}
                <Contact />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
                
                {/* Demo Sidebar 
                <DemoSidebar />
                */}
            </>
        );
    }
}
 
export default Home;