import React from 'react';
 
class HowWeWork extends React.Component {
    render(){
        return (
            <section className="how-work ptb-80">
                <div className="container">
                <div className="section-title">
                        <h2>Our <span>Process</span></h2>
                        <p>Our methodology encompasses a scalable six-phase approach, tailored to deliver solutions that precisely meet your requirements.</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-how-work">
                                {/*<h3>Phase 1</h3>
                                <h4>Planning</h4>*/}
                                <h3>Planning</h3>
                                <p>We actively collaborate with you to meticulously plan and establish detailed timelines for implementing the tailored solution, ensuring alignment with your specific needs.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-how-work">
                                {/*<h3>Phase 2</h3>
                                <h4>Designing</h4>*/}
                                <h3>Design</h3>
                                <p>We deliver comprehensive design documents, covering system architecture and detailed process flows, to ensure clear understanding and seamless implementation of the project.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-how-work">
                                {/*<h3>Phase 3</h3>
                                <h4>Development</h4>*/}
                                <h3>Development</h3>
                                <p>Adopting Agile methodologies, we offer regular builds upon completing sprints, ensuring continuous progress and adaptability throughout the development process.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-how-work">
                                {/*<h3>Phase 4</h3>
                                <h4>QA/UAT</h4>*/}
                                <h3>Testing</h3>
                                <p>We supply test plans for execution by either our internal QA team or yours, paving the way for the crucial User Acceptance Testing phase.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-how-work">
                                {/*<h3>Phase 5</h3>
                                <h4>Production Rollout</h4>*/}
                                <h3>Launch</h3>
                                <p>We collaborate closely with you during the solution's go-live, offering tailored support, whether remote or on-site, to ensure a smooth transition.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-how-work">
                                {/*<h3>Phase 6</h3>
                                <h4>Support/Enhancements</h4>*/}
                                <h3>Post-Launch</h3>
                                <p>Our engagement continues beyond the product's launch, as we also provide post-implementation support to address any subsequent needs or issues.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default HowWeWork;