import React from 'react';
import imgSpeedFlexibility from '../../assets/images/different-speed-flexibility-1.jpg';
import imgTechStack from '../../assets/images/different-tech-stack-1.jpg';
import imgExperienceStaff from '../../assets/images/different-experienced-staff-1.jpg';
 
class WhyWeDifferent extends React.Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    render(){
        return (
            <section className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Why We <span>Are Different</span></h2>
                        <p>We are a passionate group with a love for new technology and how it changes the world. We love to dive in to solve new integration and data challenges.</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                                {/* <!-- Tab 1 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'speed-flexibility')} 
                                    className="tablinks checked" 
                                    name="tabset" 
                                    id="tab1" 
                                    aria-controls="speed-flexibility"  
                                    htmlFor="tab1"
                                >
                                    Speed and Flexibility
                                </label>
                                {/* <!-- Tab 2 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'profeesional-work')} 
                                    className="tablinks" 
                                    name="tabset" 
                                    id="tab2" 
                                    aria-controls="profeesional-work"  
                                    htmlFor="tab2"
                                >
                                    Technology Stack
                                </label>
                                {/* <!-- Tab 3 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'experienced-staff')} 
                                    className="tablinks" 
                                    name="tabset" 
                                    id="tab3" 
                                    aria-controls="experienced-staff"  
                                    htmlFor="tab3"
                                >
                                    Experienced Staff
                                </label>
                                <div className="tab-panels">
                                    <section id="speed-flexibility" className="tab-panel" style={{display: "block"}}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Speed and Flexibility</h4>
                                                    <p>We leverage cloud technologies and an agile development process to deliver solutions fast.</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Experienced in various levels of AWS from IAAS to PAAS</li>
                                                        <li><i className="fa fa-check"></i>We break your project down into a product roadmap</li>
                                                        <li><i className="fa fa-check"></i>Integration experience with a large list of systems in a variety of industries</li>
                                                        <li><i className="fa fa-check"></i>Core framework to accelerate delivery turnaround</li>
                                                        <li><i className="fa fa-check"></i>Hands on Industry experience with several medium sized companies</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgSpeedFlexibility} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    
                                    <section id="profeesional-work" className="tab-panel">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Technology Stack</h4>
                                                    <p>We leverage a modern technology stack to enable our solutions to be deployed in your cloud or data center.</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>OLTP Storage with Oracle, SQL Server, or Postgres</li>
                                                        <li><i className="fa fa-check"></i>Backend layers built in .NET Core deployable to any OS platform</li>
                                                        <li><i className="fa fa-check"></i>UI layers built in Blazor, Vue, or React</li>
                                                        <li><i className="fa fa-check"></i>OLAP Analytics Storage in Redshift for big data and cloud deployments</li>
                                                        <li><i className="fa fa-check"></i>Data and Integration Orchestration with support for batch, event driven, and real-time tasks</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgTechStack} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    
                                    <section id="experienced-staff" className="tab-panel">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Experienced staff</h4>
                                                    <p>Our team has a broad range of experience in a number of industries. This diverse portfolio gives us a leg up on the competition.</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Scalable architecture design and implementation</li>
                                                        <li><i className="fa fa-check"></i>Power Generation and IPP</li>
                                                        <li><i className="fa fa-check"></i>Commercial and Commodities Trading</li>
                                                        <li><i className="fa fa-check"></i>eCommerce and multi-channeled product sales</li>
                                                        <li><i className="fa fa-check"></i>Warehousing and Manufacturing</li>
                                                        <li><i className="fa fa-check"></i>Bitcoin Minining and Trading</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgExperienceStaff} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WhyWeDifferent;