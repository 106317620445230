import React from 'react';
import logo from '../../assets/images/logo_transparent_225.png';

 
class Footer extends React.Component {
    render(){
        const currentYear = new Date().getFullYear();
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <img src={logo} alt="logo-img"/>
                            {/*}
                            <ul>
                                <li><Link to="https://www.facebook.com/" className="fa fa-facebook" target="_blank"></Link></li>
                                <li><Link to="https://twitter.com/" className="fa fa-twitter" target="_blank"></Link></li>
                                <li><Link to="#" className="fa fa-skype" target="_blank"></Link></li>
                                <li><Link to="https://www.instagram.com/" className="fa fa-instagram" target="_blank"></Link></li>
                                <li><Link to="https://www.linkedin.com/" className="fa fa-linkedin" target="_blank"></Link></li>
                            </ul>
                            */}
                            <p>Copyright <a href="https://nrtg.io" target="_blank" rel="noopener noreferrer">NextReach Technology Group LLC</a> dba Syncronyx.io <i className="fa fa-copyright"></i> {currentYear} All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;