import React from 'react';
import aboutImg from '../../assets/images/about-strategy.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>About <span>Us</span></h2>                        
                        <p>At syncronyx, our core expertise is in masterfully integrating diverse enterprise data sources into a cohesive, scalable platform. We excel in not only automating workflows but also in crafting custom connections between systems that traditionally do not communicate with each other. Our solutions are tailored to bridge these communication gaps, ensuring seamless data flow and providing insightful analytics. We're dedicated to turning complex data environments into streamlined, user-centric experiences, empowering your business with data that truly works for you.</p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6 col-md-5">
                        <img src={aboutImg} alt="about-img" />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2>Our <span>portfolio</span> showcases a diverse range of custom-developed solutions, including:</h2>
                                </div>

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Software Integrations: Seamlessly connecting disparate software systems to enhance operational efficiency.</li>
                                    <li><i className="fa fa-check"></i>Data Aggregations: Expertly compiling and organizing data from multiple sources for comprehensive analysis.</li>
                                    <li><i className="fa fa-check"></i>Automations: Streamlining processes to reduce manual effort and increase productivity.</li>
                                    <li><i className="fa fa-check"></i>Insights: Delivering actionable intelligence from complex data sets to inform strategic decisions.</li>
                                    <li><i className="fa fa-check"></i>APIs: Creating robust Application Programming Interfaces for versatile data interaction and integration.</li>
                                    <li><i className="fa fa-check"></i>AI Solutions: Implementing advanced AI technologies to drive innovation, enhance analytics, and automate decision-making processes.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;